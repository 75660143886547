import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../Layouts/index";
// import cover from '../../images/beliefs.png';
import Newsletter from "../../components/Newsletter";

class Beliefs extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-beliefs common">
        <SEO title="Our Beliefs" />
        <div className="cover-container">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div className="wrap-hero">
              <h1 className="hero-title">Our Beliefs</h1>
              {/*<h4 className="hero-sub-title">Our beef beliefs</h4>*/}
            </div>
          </div>
        </div>

        <div className=" para-one text-center">
          {/* <div className="title-bel p-5"> SPKL is for people who love delicious, quality food.</div> */}
          <h2 className="title-2 color-primary text-uppercase">
            <div className="bar-cover">
              <div className="bar" />
            </div>
            <div className="wrapper-m">
              <div className="title-m">
                {" "}
                We believe how we do things is just as important as what we do{" "}
              </div>
            </div>
            <div className="bar-cover">
              {" "}
              <div className="bar2" />
            </div>
          </h2>
        </div>

        <div className="wrapper-bottom">
          {/* <div className="wrapper-text text-uppercase title-4 text-center color-white">
             <div className="txt-mid"> These are the five core values that guide how we do things at SPKL</div>
        </div> */}

          <div className="container">
            <div className="row">
              <div className="gutter col-12 col-md-6">
                <div class="card-body text-center">
                  <h4 className="color-primary title-4">
                    {" "}
                    Striving for excellence
                  </h4>
                  <p className="para-three color-white">
                    {" "}
                    We are committed to consistently delivering an outstanding
                    flavour and eating experience
                  </p>
                </div>
              </div>
              <div className="gutter col-12 col-md-6">
                <div class="card-body text-center">
                  <h4 className="color-primary title-4">
                    {" "}
                    Pushing the boundaries
                  </h4>
                  <p className="para-three color-white">
                    We’re always innovating with our producers and partners in
                    order to advance our product and the Australian beef
                    industry at large.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="gutter col-12 col-md-6">
                <div class="card-body text-center">
                  <h4 className="color-primary title-4">
                    {" "}
                    Building relationships, not transactions
                  </h4>
                  <p className="para-three color-white">
                    We are a business that behaves like a family, building
                    lasting relationships through honesty and transparency.
                  </p>
                </div>
              </div>
              <div className="gutter col-12 col-md-6">
                <div class="card-body text-center">
                  <h4 className="color-primary title-4">
                    {" "}
                    Sustainability for the future
                  </h4>
                  <p className="para-three color-white">
                    We treat our cows and bulls with the respect and care they
                    deserve, and always strive to progress sustainable industry
                    practices.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="gutter col-12 col-md-6 offset-md-3">
                <div class="card-body text-center">
                  <h4 className="color-primary title-4">
                    {" "}
                    Share the success
                  </h4>
                  <p className="para-three color-white">
                    {" "}
                    We champion Australia as a leader in the Beef Industry and
                    believe in doing our bit to fuel the prosperity of our
                    country
                  </p>
                </div>
              </div>
            </div>
            <div className="p-5" />
          </div>
        </div>
        <Newsletter />
      </Layout>
    );
  }
}

export default Beliefs;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "beliefs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
